import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
Vue.use(Router);

const router = new Router({
  routes: []
});

// 全局导航守卫之登录拦截
// 如果你去的是登录，就next；如果去的不是登录，那么就判断是否登录过。登录过，next；否则全部跳转到登录页
// router.beforeEach((to,from,next)=>{
// 	document.title = '跳转中';
// })
router.beforeEach(()=>{
	document.title = '跳转中';
})
  router

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
