<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: "APP",
		data() {
			return {
				code: "",
			};
		},
		methods: {
			getQuery() {
				let url = window.location.search; //获取url中"?"符后的字串
				let query = new Object();
				if (url.indexOf("?") != -1) {
					let str = url.substr(1);
					let strs = str.split("&");
					for (let i = 0; i < strs.length; i++) {
						query[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
					}
				}
				return query;
			},
			gotoAuthoriza() {
				// let redirect = this.getQueryString("url");
				// let option = this.getQueryString("option");
				let appid = this.getQueryString("appid");
				let redirect_url = encodeURIComponent(window.location.href);
				
				console.log(redirect_url,'redirect_url');
				let url =
					`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
				window.location.href = url;
				
			},
			//  getPara(name){
			//  let url = window.location.href;
			//  eval("let reg = /("+name+"=[A-Za-z0-9_,-@!#\.\:\/]*)/i")
			//  let match = url.match(reg);
			//  if(match && match.length > 1){
			//    let arr = match[0].split("=");
			//  arr.shift();
			//  return arr.join('=');
			//  }else{
			//   return "";
			//  }
			// },
			getQueryString(name) {
				let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
				let r = window.location.search.substr(1).match(reg);
				if (r != null) {
					return decodeURIComponent(r[2]);
				}
				return null;
			},
		},
		beforeCreate() {
			document.title = '跳转中';
		},
		//
		created() {
			// window.document.title = '跳转中';
			// document.title = '跳转中';
			let option = this.getQueryString("option");
			let code = this.getQuery().code;
			if (option == "jump") {
				let redirect = this.getQueryString("url");
				window.location.href = redirect;
			} else if (option == "getCode") {
				if (!code) {
					this.gotoAuthoriza();
				} else {
					let redirect = this.getQueryString("url");
					if (redirect.split("?").length > 1) {
						window.location.href = redirect + "&code=" + code;
					} else {
						window.location.href = redirect + "?code=" + code;
					}
				}
			}
		},
	};
</script>
